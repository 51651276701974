<template>
  <div
    class="card NewsroomCard max-width-full is-width-full is-flex is-column is-expanded"
    data-testid="NewsroomCard"
  >
    <div v-bind="attributes" class="card-content is-flex is-column is-aligned-center is-expanded">
      <div class="NewsroomCard__logo m-b-l is-flex is-aligned-center">
        <router-link
          v-if="newsroom.id"
          :to="{ name: 'newsroom.stories', params: { newsroomSlug: newsroom.slug } }"
          itemprop="logo"
          itemscope=""
          itemtype="https://schema.org/ImageObject"
          active-class="is-active"
        >
          <round-avatar
            :src="newsroom.logo.cropped"
            :alt="newsroom.name"
            :acronym-fallback="newsroom.name"
            :main-color="newsroom.main_color"
            size="xx-large"
          />

          <meta :content="newsroom.logo.cropped" itemprop="url">
        </router-link>
      </div>

      <h4 itemprop="name" class="NewsroomCard__name m-b-none">
        {{ newsroom.name }}
      </h4>

      <website-link
        v-if="newsroom.website"
        :href="newsroom.website"
        class="NewsroomCard__link is-block break-words is-size-7 has-text-muted"
        itemprop="sameAs"
      />

      <v-separator
        v-if="country && newsroom.description"
        fat
        darker
      />

      <div
        :title="newsroom.description"
        itemprop="description"
        class="NewsroomCard__desc has-text-muted m-b-m break-words"
      >
        {{ truncate(newsroom.description, truncateDescription) }}
      </div>

      <div v-if="country" class="NewsroomCard__country-wrap is-flex is-aligned-middle">
        <div class="NewsroomCard__country-icon">
          <v-icon type="globe" size="lg" />
        </div>

        <div class="NewsroomCard__country-meta m-l-s">
          <div class="NewsroomCard__country has-text-weight-semibold">
            {{ country.name }}
          </div>

          <div class="NewsroomCard__state">
            {{ newsroom.state }}
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer NewsroomCard__footer p-a-s">
      <div class="is-aligned-justify columns is-expanded is-multiline is-variable-grid is-2">
        <div class="is-narrow column">
          <router-link
            v-if="!$isEmpty(newsroom)"
            :to="{ name: 'newsroom.stories', params: { newsroomSlug: newsroom.slug } }"
            data-testid="newsroomLink"
            class="button is-primary is-outlined is-small"
            active-class="is-active"
          >
            {{ $t('pages.dashboard.newsroom') }}
          </router-link>
        </div>

        <div class="is-narrow column">
          <follow-newsroom-button :newsroom="newsroom" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'

export default {
  mixins: [TruncateMixin],

  props: {
    newsroom: {
      type: Object,
      required: true
    },
    authorSchema: {
      type: Boolean,
      default: false
    },
    truncateDescription: {
      type: Number,
      default: 250
    }
  },

  computed: {
    attributes () {
      const attrs = {
        itemscope: true,
        itemtype: 'https://schema.org/Organization'
      }
      if (this.authorSchema) attrs.itemprop = 'author'
      return attrs
    },
    /**
     * Returns the first newsroom country
     * @return {HF_Country}
     */
    country () {
      return this.$safeGet(this.newsroom, 'country.data')
    }
  }
}
</script>
